import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Chart } from "react-google-charts";
import { Spotifylogin } from "../components/Spotify";
import { useLocation } from "react-router-dom";
const ServicesSkills = () => {
  const [User, setUser] = useState();
  const [collection, setCollection] = useState([]);

  const [selectedObj, setSelectedObj] = useState({});
  const [collection1, setCollection1] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setCurrentSort] = useState("");
  const [currentOption, setCurrentOption] = useState(null);
  const [graphOptions, setOptions] = useState();
  const [graphData, setGraphData] = useState();
  const audioRefs = React.useRef({});
  const location = useLocation();
  const PerPage = 30;
  const totalPages = Math.ceil((collection?.length || 0) / PerPage);
  const maxPagesToShow = 3;
  useEffect(() => {
    const GetKey = async (code) => {
      try {
        const response = await axios.post(`/GetKey/${code}`);
        let key = response.data;
        setUser(key); //key.expires_in = Date.now() + 3600 * 1000; localStorage.setItem("userKey", JSON.stringify(key));
      } catch (error) {
        console.error("Error fetching key:", error);
      }
    };
    const code = new URLSearchParams(location.search).get("code");
    if (code) {
      GetKey(code);
    } else {
      localStorage.clear();
    }
  }, [location]);
  useEffect(() => {
    const GetSongsJson = async () => {
      const response = await axios.get(`assets/songs.json`);
      let data = response.data;
      setCollection(data);
      setCollection1([
        ...new Set(
          data.flatMap((g) =>
            g.genres
              .toLowerCase()
              .split(",")
              .map((g) => g.replace(",", "").trim())
              .filter((g) => g !== "")
          )
        ),
      ]);
    };
    GetSongsJson();
  }, []);
  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      setSelectedObj([]);
    }
  };
  const selectGenre = (genre) => {
    let currentList = [...collection];
    genre = genre.toLowerCase();
    if (genre === "") {
      GetSongsJson();
      setSelectedGenres([]);
      return currentList;
    } else {
      setSelectedGenres((prevSelected) => {
        let updatedGenres;
        if (prevSelected.includes(genre)) {
          updatedGenres = prevSelected.filter((g) => g !== genre);
        } else {
          updatedGenres = [...prevSelected, genre];
        }
        if (updatedGenres.length > 0) {
          currentList = currentList.filter((a) => {
            const trackGenres = a.genres.split(",").map((g) => g.trim().toLowerCase());
            const updatedGenresLower = updatedGenres.map((p) => p.toLowerCase());
            return trackGenres.some((g) => updatedGenresLower.some((updatedGenre) => g.includes(updatedGenre)));
          });
        }
        setCollection(currentList);
        return updatedGenres;
      });
    }
    setCurrentPage(1);
  };
  const selectItem = (movieId) => {
    if (movieId !== selectedObj.id) {
      const selectedTrack = collection.find((movie) => movie.id === movieId);
      playTrack(selectedTrack.id);
      setSelectedObj(selectedTrack);
    } else {
      playTrack(movieId);
      setSelectedObj([]);
    }
  };
  const GetSongsJson = async () => {
    const response = await axios.get(`assets/songs.json`);
    let data = response.data;
    setCollection(data);
  };
  const getValue = (item, key) => {
    if (key === "release_date" || key === "added_at") {
      return new Date(item[key]);
    } else {
      return Number(item[key]) ?? 0;
    }
  };
  const handleSort = (event) => {
    let currentList = [...collection];
    const sortFunctions = {
      duration_ms: (a, b) => getValue(b, "duration_ms") - getValue(a, "duration_ms"),
      popularity: (a, b) => getValue(b, "popularity") - getValue(a, "popularity"),
      danceability: (a, b) => getValue(b, "danceability") - getValue(a, "danceability"),
      energy: (a, b) => getValue(b, "energy") - getValue(a, "energy"),
      valence: (a, b) => getValue(b, "valence") - getValue(a, "valence"),
      loudness: (a, b) => getValue(b, "loudness") - getValue(a, "loudness"),
      speechiness: (a, b) => getValue(b, "speechiness") - getValue(a, "speechiness"),
      liveness: (a, b) => getValue(b, "liveness") - getValue(a, "liveness"),
      tempo: (a, b) => getValue(b, "tempo") - getValue(a, "tempo"),
      release_date: (a, b) => getValue(b, "release_date") - getValue(a, "release_date"),
      date_added: (a, b) => getValue(b, "date_added") - getValue(a, "date_added"),
    };
    const order = event.target.value;
    setCurrentSort(order);
    if (order && sortFunctions[order]) {
      const sorted = [...currentList].sort(sortFunctions[order]);
      setCollection(sorted);
      setSelectedObj([]);
    } else {
      setCollection([...currentList].sort((a, b) => new Date(b.Released) - new Date(a.Released)));
    }
    setCurrentPage(1);
  };
  const handleReverse = () => {
    let currentList = [...collection];
    const sorted = [...currentList].reverse();
    setCollection(sorted);
    setSelectedObj([]);
    setCurrentPage(1);
  };
  const multiply = (a, b) => {
    return a.map((row) => b[0].map((_, colIndex) => row.reduce((sum, el, rowIndex) => sum + el * b[rowIndex][colIndex], 0)));
  };
  const transpose = (matrix) => {
    return matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
  };
  const invert = (matrix) => {
    const size = matrix.length;
    const identity = Array.from({ length: size }, (_, i) => Array.from({ length: size }, (_, j) => (i === j ? 1 : 0)));
    const augmented = matrix.map((row, i) => [...row, ...identity[i]]);
    for (let i = 0; i < size; i++) {
      const factor = augmented[i][i];
      for (let j = 0; j < augmented[i].length; j++) {
        augmented[i][j] /= factor;
      }
      for (let k = 0; k < size; k++) {
        if (k !== i) {
          const factor = augmented[k][i];
          for (let j = 0; j < augmented[k].length; j++) {
            augmented[k][j] -= factor * augmented[i][j];
          }
        }
      }
    }
    return augmented.map((row) => row.slice(size));
  };
  const updateGraph = useCallback((items, graphOption) => {
    let Values = [];
    let data = [];
    if (items.length > 0) {
      items.forEach((item, index) => {
        Values.push([index, getValue(item, graphOption)]);
      });
      let degree = 8;
      let X = Values.map(([x]) => Array.from({ length: degree + 1 }, (_, i) => Math.pow(x, i)));
      let Y = Values.map(([, y]) => [y]);
      let XT = transpose(X);
      let XTX = multiply(XT, X);
      let XTY = multiply(XT, Y);
      let XTXInv = invert(XTX);
      let coefficients = multiply(XTXInv, XTY).map((row) => row[0]);
      Values.forEach(([x, y]) => {
        const Obj = items.at(x);
        var date = Obj.added_at.slice(5, 7) + "/" + Obj.added_at.slice(8, 10) + "/" + Obj.added_at.slice(2, 4);
        let regressionY = coefficients.reduce((sum, coef, i) => sum + coef * Math.pow(x, i), 0);
        data.push([new Date(date), y, `${date}: ${Obj.name} by ${Obj.artists}  ${graphOption}: ${y}`, regressionY, `${date}: ${Obj.name} by ${Obj.artists} ${graphOption}: ${y}`]);
      });
      data.unshift(["Order", graphOption, { type: "string", role: "tooltip" }, "Polynomial Regression", { type: "string", role: "tooltip" }]);

      let options = {
        title: `Polynomial Regression: Song's ${graphOption} By Order`,
        curveType: "function",
        legend: { position: "bottom" },
        hAxis: { title: "Order" },
        vAxis: { title: graphOption },
      };
      setCurrentOption(graphOption);
      setGraphData(data);
      setOptions(options);
    }
  }, []);
  const handleGraphChange = (event) => {
    var items = [...collection];
    const graphOption = event.target.value;
    updateGraph(items, graphOption);
  };
  useEffect(() => {
    const items = [...collection];
    if (currentOption) {
      updateGraph(items, currentOption);
    } else {
      updateGraph(items, "valence");
    }
  }, [collection, currentOption, updateGraph]);

  const handleTrackEnd = () => {
    const currentIndex = collection.findIndex((track) => track.id === selectedObj.id);
    if (currentIndex !== -1) {
      if (currentIndex < collection.length - 1) {
        const nextTrackId = collection[currentIndex + 1].id;
        playTrack(nextTrackId);
      }
    }
  };
  const playTrack = (index) => {
    if (index === selectedObj.id) {
      audioRefs.current[index].pause();
      audioRefs.current[index].currentTime = 0;
    } else {
      audioRefs.current[index]?.play();
    }
  };
  async function fetchWebApi(endpoint, method, body, access_token) {
    const response = await fetch(`https://api.spotify.com/v1/${endpoint}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      method,
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      const errorResponse = await response.json().catch(() => ({}));
      return {
        error: "API_ERROR",
        message: `Spotify API responded with status ${response.status}`,
        details: errorResponse,
        status: response.status,
      };
    }
    const data = await response.json();
    return data;
  }
  const fetchTracks = async (totalTracks, token, query) => {
    const tracks = [];
    const limit = 50;
    for (let offset = 0; offset < totalTracks; offset += limit) {
      const response = await fetchWebApi(query + `limit=${limit}&offset=${offset}`, "GET", token);
      tracks.push(...response.items);
    }

    return tracks;
  };
  // const fetchArtists = async (artistIds, token) => {
  //   const artists = [];
  //   const limit = 50;
  //   const artistSet = new Set();
  //   for (let offset = 0; offset < artistIds.length; offset += limit) {
  //     const idsBatch = artistIds.slice(offset, offset + limit).join(",");
  //     const response = await fetchWebApi(`artists?ids=${idsBatch}`, "GET", token);
  //     response.artists.forEach((artist) => {
  //       if (!artistSet.has(artist.id)) {
  //         artistSet.add(artist.id);
  //         artists.push(artist);
  //       }
  //     });
  //   }
  //   return artists;
  // };
  const getTracks = async (length) => {
    if (!User || Date.now() > Number(User.expires_in)) throw new Error("User key expired or invalid");
    let query = "";
    if (length) {
      query = `me/top/tracks?time_range=${length}&`;
    } else {
      query = "me/tracks?";
    }
    const userTracksResponse = await fetchWebApi(query + "&limit=1&offset=0", "GET", User.access_token);
    const totalTracks = userTracksResponse.total;

    const tracks = await fetchTracks(totalTracks, User.access_token, query);
    //const trackIds = tracks.map((track) => track.track.id);
    //const audioFeatures = await fetchAudioFeatures(trackIds, User.access_token);

    //const uniqueArtistIds = [...new Set(tracks.flatMap((track) => track.track.artists.map((artist) => artist.id).filter((id) => id)))];
    //const artists = await fetchArtists(uniqueArtistIds, User.access_token);

    //const finalTracks = buildTracksData(tracks, audioFeatures, userId, artists);
    setCollection(tracks);
  };
  const saveSongs = async () => {
    let saveCollection = collection.map((track) => "spotify:track:" + track.id);
    let season = ((date) => `${["Winter", "Spring", "Summer", "Fall"][(((new Date(date).getMonth() + 1) % 12) / 3) | 0]} ${new Date().getFullYear()}`)(new Date().toISOString());
    var name = `${season}`;
    var answer = window.confirm(`Are you sure you want to save: ${name}?`);
    var playlistDescription = `Playlist created on royclaudio.com for ${season}`;
    const res1 = await fetchWebApi(`users/${User.id}/playlists`, "POST", { name: name, description: playlistDescription }, User.access_token);
    let offset = 0;
    let limit = 50;
    if (answer) {
      while (offset < saveCollection.length) {
        let uriArray = saveCollection.slice(offset, offset + limit);
        await fetchWebApi(`playlists/${res1.id}/tracks`, "POST", { uris: uriArray }, User.access_token);
        offset += limit;
      }
      const res3 = await fetchWebApi(`playlists/${res1.id}`, "GET", null, User.access_token);
      //setCollection3(res3);
      console.log(res3);
    }
  };

  const goTo = (Link) => {
    window.location.href = Link;
  };
  return (
    <section className="section">
      <h1 className="text-center">Music</h1>
      <h5 className="text-center">Roy's Liked Songs</h5>
      {!User && (
        <button className="button-54" style={{ position: "absolute", top: "0" }} onClick={Spotifylogin}>
          Login
        </button>
      )}
      <Chart chartType="LineChart" width="100%" height="25vmin" style={{ margin: "0", padding: "0" }} data={graphData} options={graphOptions} legendToggle />
      <div className="consent-banner justify-content-start">
        <p>Change chart y axis</p>
        <select aria-label="Order Selection" className="button-54" onChange={(event) => handleGraphChange(event)}>
          <option value="valence">valence</option>
          <option value="danceability">danceability</option>
          <option value="energy">energy</option>
          <option value="popularity">popularity</option>
        </select>
        <button className="button-54" onClick={() => goTo("/CytoScape_M")}>
          Explore The CytoScape
        </button>
        <p className="text-center">A Polynomial Regression is a mathematical equation that describes a non-linear relationship between an independent variable (x) and a dependent variable (y) using powers of x, this regression uses historical data to identify patterns of this data.</p>

      </div>
      {User && (
        <div className="consent-banner justify-content-center">
          <button className="button-54" onClick={() => getTracks("long_term")}>
            <span>top </span>~1 year
          </button>
          <button className="button-54" onClick={() => getTracks("medium_term")}>
            <span>top </span>6 months
          </button>
          <button className="button-54" onClick={() => getTracks("short_term")}>
            <span>top </span>4 weeks
          </button>
          <button className="button-54" onClick={() => getTracks()}>
            <span>all </span> Songs
          </button>
          <button className="button-54" onClick={() => saveSongs(collection, selectedGenres.length > 0 ? selectedGenres.join(" ") : "")}>
            <span>Save to</span> playlist
          </button>
        </div>
      )}
      <div className="consent-banner justify-content-start">
        {collection1
          .filter((a) => !selectedGenres.includes(a.toLowerCase()) && collection.filter((b) => b.genres.split(",").includes(a.toLowerCase())).length)
          .sort()
          .map((g) => (
            <button id={g} key={g} className="button-54" onClick={() => selectGenre(g)}>
              {g}({collection.filter((a) => a.genres.split(",").includes(g)).length})
            </button>
          ))}
      </div>
      <div className="consent-banner justify-content-start">
        <select key="selectGenre1" aria-label="Order Selection" className="button-54" onChange={(event) => handleSort(event)}>
          <option value="added_at">Earliest</option>
          <option value="valence">valence</option>
          <option value="danceability">danceability</option>
          <option value="energy">energy</option>
          <option value="popularity">popularity</option>
        </select>
        <button key="Reverse" id="rocket" className="button-54" onClick={() => handleReverse()}>
          <svg width="24px" height="24px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" strokeWidth="0.00024000000000000003">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.528"></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M9 8.24994C8.81 8.24994 8.62 8.17994 8.47 8.02994L6.5 6.05994L4.53 8.02994C4.24 8.31994 3.76 8.31994 3.47 8.02994C3.18 7.73994 3.18 7.25994 3.47 6.96994L5.97 4.46994C6.26 4.17994 6.74 4.17994 7.03 4.46994L9.53 6.96994C9.82 7.25994 9.82 7.73994 9.53 8.02994C9.38 8.17994 9.19 8.24994 9 8.24994Z" fill="#000000"></path>{" "}
              <path d="M6.5 19.75C6.09 19.75 5.75 19.41 5.75 19V5C5.75 4.59 6.09 4.25 6.5 4.25C6.91 4.25 7.25 4.59 7.25 5V19C7.25 19.41 6.91 19.75 6.5 19.75Z" fill="#000000"></path> <path d="M20 17.25H12C11.59 17.25 11.25 16.91 11.25 16.5C11.25 16.09 11.59 15.75 12 15.75H20C20.41 15.75 20.75 16.09 20.75 16.5C20.75 16.91 20.41 17.25 20 17.25Z" fill="#000000"></path>{" "}
              <path d="M16 11.25H12C11.59 11.25 11.25 10.91 11.25 10.5C11.25 10.09 11.59 9.75 12 9.75H16C16.41 9.75 16.75 10.09 16.75 10.5C16.75 10.91 16.41 11.25 16 11.25Z" fill="#000000"></path> <path d="M14 8.25H12C11.59 8.25 11.25 7.91 11.25 7.5C11.25 7.09 11.59 6.75 12 6.75H14C14.41 6.75 14.75 7.09 14.75 7.5C14.75 7.91 14.41 8.25 14 8.25Z" fill="#000000"></path>{" "}
              <path d="M18 14.25H12C11.59 14.25 11.25 13.91 11.25 13.5C11.25 13.09 11.59 12.75 12 12.75H18C18.41 12.75 18.75 13.09 18.75 13.5C18.75 13.91 18.41 14.25 18 14.25Z" fill="#000000"></path>
            </g>
          </svg>
        </button>
      </div>
      <nav style={{ width: "30%" }}>
        <ul style={{ overflowX: "hidden" }}>
          {selectedGenres.length > 0 && (
            <li id="r11111" key="r11111">
              <div>
                <h5>Filters</h5>
              </div>
            </li>
          )}
          {selectedGenres.length > 0 && (
            <li role="button" id="reset" key="reset" className="button-54" onClick={() => selectGenre("")}>
              <div className="section-item">
                <div className="">
                  <h5>Remove All</h5>
                </div>
              </div>
            </li>
          )}
          {selectedGenres.length > 0 && (
            <li id="r1111" key="r1111">
              <div>
                <h5>Genres</h5>
              </div>
            </li>
          )}
          {selectedGenres
            .filter((a) => selectedGenres.includes(a.toLowerCase()))
            .map((g) => (
              <li role="button" id={"g" + g} key={"g" + g} className="button-54" onClick={() => selectGenre(g)}>
                <div className="section-item">
                  <h5>{g}</h5>
                </div>
              </li>
            ))}
          {currentSort && (
            <li id="r1" key="r1">
              <div>
                <h5>sorting by: {currentSort}</h5>
              </div>
            </li>
          )}
          <li id="r" key="r">
            <div>
              <h5>{collection.length}&nbsp;Songs</h5>
            </div>
          </li>
          {collection.slice((currentPage - 1) * PerPage, (currentPage - 1) * PerPage + PerPage).map((item) => (
            <li role="button" id={item.id} key={item.id} className={selectedObj.id === item.id ? "button-54 active1" : "button-54"} onClick={() => selectItem(item.id)}>
              <div className="section-item">
                <img className="" loading="lazy" src={item.image} alt={`${item.name} track cover`} />
                <div className="">
                  <h5>{item.name}</h5>
                  <h6>{item.artists}</h6>
                </div>
              </div>
              <audio ref={(el) => (audioRefs.current[item.id] = el)} onEnded={handleTrackEnd} key={item.preview_url}>
                <source src={item.preview_url} type="audio/mpeg" />
              </audio>
            </li>
          ))}
        </ul>
      </nav>
      <article style={{ width: "70%" }}>
        {selectedObj.name ? (
          <>
            <h2>{selectedObj.name}</h2>
            <h3>{selectedObj.artists}</h3>
            <p>Performed by: {selectedObj.artists}</p>
            <p> {"Added " + selectedObj.added_at.slice(5, 7) + "/" + (selectedObj.added_at.slice(8, 10) ? selectedObj.added_at.slice(8, 10) + "/" : "") + selectedObj.added_at.slice(0, 4)}</p>
            <table className="table table-bordered">
              <tbody>
                {Object.entries(selectedObj).map(([key, value]) => {
                  if (key !== "added_at" && key !== "duration_ms" && key !== "id" && key !== "preview_url" && key !== "image" && key !== "link" && key !== "artistsIDs" && key !== "genres" && key !== "userId" && key !== "artists" && key !== "name") {
                    if (key === "duration_ms") {
                      value = `${Math.floor(selectedObj.duration_ms / 60000)}:${((selectedObj.duration_ms % 60000) / 1000).toFixed(0)}`;
                    }
                    return (
                      <tr key={key}>
                        <th className="py-1">{key}</th>
                        <td className="py-1" align="left">
                          {value}
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
                <tr align="center">
                  <td colSpan="2">
                    <a href={selectedObj.link} target="_blank" rel="noopener noreferrer">
                      Spotify
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <h2>Select A Song</h2>
            <h3>See its details</h3>
            <p>Danceability: Danceability describes how suitable a track is for dancing based on a combination of musical elements including tempo, rhythm stability, beat strength, and overall regularity. A value of 0.0 is least danceable and 1.0 is most danceable.</p>
            <p>Energy: Energy is a measure from 0.0 to 1.0 and represents a perceptual measure of intensity and activity. Typically, energetic tracks feel fast, loud, and noisy.</p>
            <p>Valence: A measure from 0.0 to 1.0 describing the musical positiveness conveyed by a track. Tracks with high valence sound more positive (e.g. happy, cheerful, euphoric), while tracks with low valence sound more negative (e.g. sad, depressed, angry).</p>
            <p>popularity: The value will be between 0 and 100, with 100 being the most popular. The popularity of a track is a value between 0 and 100, with 100 being the most popular. The popularity is calculated by algorithm and is based, in the most part, on the total number of plays the track has had and how recent those plays are.</p>
          </>
        )}
      </article>
      <div className="dock-bottom">
        <div className="consent-banner justify-content-center">
          <button className="button-54" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Prev
          </button>
          {currentPage > Math.ceil(maxPagesToShow / 2) && (
            <button className="button-54" onClick={() => handlePageChange(1)}>
              1
            </button>
          )}
          {currentPage > Math.ceil(maxPagesToShow / 2) && <span>...</span>}
          {getVisiblePages().map((page) => (
            <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "button-54 active1" : "button-54"}>
              {page}
            </button>
          ))}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span>...</span>}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && (
            <button className="button-54" onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </button>
          )}
          <button className="button-54" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
    </section>
  );
};
export default ServicesSkills;
