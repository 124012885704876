import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Chart } from "react-google-charts";
import RatingComponent from "../components/RatingComponent";
const Threads = () => {
  const [collection, setCollection] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [collectionGenres, setCollectionGenres] = useState([]);
  const [currentGenres, setCurrentGenres] = useState([]);
  const [collectionDirector, setCollectionDirectors] = useState([]);
  const [currentDirector, setcurrentDirectors] = useState([]);
  const [collectionActor, setCollectionActor] = useState([]);
  const [collectionSearchResults, setCollectionSearchResults] = useState([]);
  const [currentActor, setcurrentActor] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchOption, setsearchOption] = useState({});
  //const [currentSort, setCurrentSort] = useState("");
  const [collectionRated, setCollectionRated] = useState([]);
  const [currentRating, setCurrentRating] = useState([]);
  const [currentOption, setCurrentOption] = useState(null);
  const [graphOptions, setOptions] = useState();
  const [graphData, setGraphData] = useState();
  const PerPage = 30;
  const totalPages = Math.ceil((collection?.length || 0) / PerPage);
  const maxPagesToShow = 3;
  useEffect(() => {
    const GetSongsJson = async () => {
      const response = await axios.get(`assets/movies.json`);
      let data = response.data;
      setCollection(data);
      setCollectionGenres([...new Set(data.flatMap((movie) => movie.Genre.split(", ").map((g) => g.trim())))]);
      setCollectionRated([...new Set(data.map((g) => g.Rated.toLowerCase().trim()))]);
      setCollectionDirectors([...new Set(data.sort((a, b) => b.Box_office - a.Box_office).flatMap((g) => g.Director.toLowerCase().split(", ")))]);
      setCollectionActor([...new Set(data.sort((a, b) => b.Box_office - a.Box_office).flatMap((g) => g.Actors.toLowerCase().split(", ")))]);
    };
    GetSongsJson();
  }, []);
  const getVisiblePages = () => {
    const pages = [];
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
    if (endPage - startPage + 1 < maxPagesToShow && totalPages > maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
      setSelectedObj([]);
    }
  };
  const selectItem = (movieId) => {
    searchFunction("option", "");
    if (movieId !== selectedObj.id) {
      const selectedTrack = collection.find((movie) => movie.id === movieId);
      setSelectedObj(selectedTrack);
    } else {
      setSelectedObj([]);
    }
  };
  const getValue = (item, key) => {
    if (key === "Released") {
      return new Date(item[key]);
    } else if (key === "Box_office" || key === "imdbVotes") {
      return Number(item[key]?.replace(/,/g, "") || 0);
    } else if (key === "Runtime") {
      return Number(item[key]?.replace(/ MIN$/i, "") || 0);
    } else if (key === "Awards") {
      const numbers = item[key].match(/\d+(\.\d+)?/g) || [];
      const sum = numbers.map(Number).reduce((acc, num) => acc + num, 0);
      return sum;
    } else {
      return ["null", "N/A"].includes(item[key]) ? 0 : Number(item[key]);
    }
  };
  const handleSort = (event) => {
    let currentList = [...collection];
    const sortFunctions = {
      imdbRating: (a, b) => getValue(b, "imdbRating") - getValue(a, "imdbRating"),
      Runtime: (a, b) => getValue(b, "Runtime") - getValue(a, "Runtime"),
      Awards: (a, b) => getValue(b, "Awards") - getValue(a, "Awards"),
      imdbVotes: (a, b) => getValue(b, "imdbVotes") - getValue(a, "imdbVotes"),
      Released: (a, b) => getValue(b, "Released") - getValue(a, "Released"),
      Genre: (a, b) => getValue(b, "Genre") - getValue(a, "Genre"),
      Box_office: (a, b) => getValue(b, "Box_office") - getValue(a, "Box_office"),
      Metascore: (a, b) => getValue(b, "Metascore") - getValue(a, "Metascore"),
    };
    const order = event.target.value;
    //setCurrentSort(order);
    if (order && sortFunctions[order]) {
      const sorted = [...currentList].sort(sortFunctions[order]);
      setCollection(sorted);
      setSelectedObj([]);
    } else {
      setCollection([...currentList].sort((a, b) => new Date(b.Released) - new Date(a.Released)));
    }
    setCurrentPage(1);
  };
  const handleReverse = () => {
    let currentList = [...collection];
    const sorted = [...currentList].reverse();
    setCollection(sorted);
    setSelectedObj([]);
    setCurrentPage(1);
  };
  const multiply = (a, b) => {
    return a.map((row) => b[0].map((_, colIndex) => row.reduce((sum, el, rowIndex) => sum + el * b[rowIndex][colIndex], 0)));
  };
  const transpose = (matrix) => {
    return matrix[0].map((_, colIndex) => matrix.map((row) => row[colIndex]));
  };
  const invert = (matrix) => {
    const size = matrix.length;
    const identity = Array.from({ length: size }, (_, i) => Array.from({ length: size }, (_, j) => (i === j ? 1 : 0)));
    const augmented = matrix.map((row, i) => [...row, ...identity[i]]);
    for (let i = 0; i < size; i++) {
      const factor = augmented[i][i];
      for (let j = 0; j < augmented[i].length; j++) {
        augmented[i][j] /= factor;
      }
      for (let k = 0; k < size; k++) {
        if (k !== i) {
          const factor = augmented[k][i];
          for (let j = 0; j < augmented[k].length; j++) {
            augmented[k][j] -= factor * augmented[i][j];
          }
        }
      }
    }
    return augmented.map((row) => row.slice(size));
  };
  const updateGraph = useCallback((items, graphOption) => {
    let Values = [];
    let data = [];
    if (items.length > 0) {
      items.forEach((item, index) => {
        Values.push([index, getValue(item, graphOption)]);
      });
      let degree = 8;
      let X = Values.map(([x]) => Array.from({ length: degree + 1 }, (_, i) => Math.pow(x, i)));
      let Y = Values.map(([, y]) => [y]);
      let XT = transpose(X);
      let XTX = multiply(XT, X);
      let XTY = multiply(XT, Y);
      let XTXInv = invert(XTX);
      let coefficients = multiply(XTXInv, XTY).map((row) => row[0]);
      Values.forEach(([x, y], idx) => {
        const Obj = items.at(idx);
        var date = new Date(Obj.Released);

        let regressionY = coefficients.reduce((sum, coef, i) => sum + coef * Math.pow(x, i), 0);
        data.push([x, y, `${date}: ${Obj.Title} by ${Obj.Director}  ${graphOption}: ${y}`, regressionY, `${date}: ${Obj.Title} by ${Obj.Director} ${graphOption}: ${y}`]);
      });
      data.unshift(["Order", graphOption, { type: "string", role: "tooltip" }, "Polynomial Regression", { type: "string", role: "tooltip" }]);

      let options = {
        title: `Polynomial Regression: Movie's ${graphOption} By Order`,
        curveType: "function",
        hAxis: { slantedText: false },
        legend: { position: "bottom" },
        vAxis: { title: graphOption },
      };
      setCurrentOption(graphOption);
      setGraphData(data);
      setOptions(options);
    }
  }, []);
  const handleGraphChange = (event) => {
    var items = [...collection];
    const graphOption = event.target.value;
    updateGraph(items, graphOption);
  };
  const handleDirectorChange = (item) => {
    var input;
    input = document.getElementById("SearchDirector");
    input.value = "";
    item = item.toLowerCase();
    if (item === "") {
      setcurrentDirectors([""]);
    } else {
      setcurrentDirectors((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const handleActorChange = (item) => {
    var input;
    input = document.getElementById("SearchActor");
    input.value = "";
    item = item.toLowerCase();
    if (item === "") {
      setcurrentActor([""]);
    } else {
      setcurrentActor((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const handleGenreChange = (item) => {
    item = item.toLowerCase();
    if (item === "") {
      setCurrentGenres([""]);
    } else {
      setCurrentGenres((prevSelected) => {
        let updateList;

        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const HandleRatingChange = (item) => {
    item = item.toLowerCase();
    if (item === "") {
      setCurrentRating([""]);
    } else {
      setCurrentRating((prevSelected) => {
        let updateList;
        if (prevSelected.includes(item)) {
          updateList = prevSelected.filter((g) => g !== item);
        } else {
          updateList = [...prevSelected, item];
        }
        return updateList;
      });
    }
  };
  const updateCollection = useCallback((items, currentRating, currentGenres, currentDirector, currentActor) => {
    if (currentDirector.length > 0) {
      items = items.filter((movie) => {
        const check = movie.Director.toLowerCase().split(", ");
        const list = currentDirector.some((a) => check.includes(a));
        return list;
      });
    }
    if (currentActor.length > 0) {
      items = items.filter((movie) => {
        const check = movie.Actors.toLowerCase().split(", ");
        const list = currentActor.some((a) => check.includes(a));
        return list;
      });
    }
    if (currentRating.length > 0 && currentGenres.length > 0) {
      currentGenres.forEach((a) => {
        items = items.filter((movie) => {
          const rating = movie.Rated.toLowerCase();
          const list = currentRating.some((a) => a.toLowerCase() === rating);
          const g1 = movie.Genre.split(", ").map((g) => g.trim().toLowerCase());
          const list1 = g1.includes(a);
          return list && list1;
        });
      });
    }
    if (currentGenres.length > 0 && currentRating.length === 0) {
      currentGenres.forEach((a) => {
        items = items.filter((movie) => {
          const g1 = movie.Genre.split(", ").map((g) => g.trim().toLowerCase());
          const list = g1.includes(a);
          return list;
        });
      });
    }
    if (currentRating.length > 0 && currentGenres.length === 0) {
      items = items.filter((movie) => {
        const rating = movie.Rated.toLowerCase();
        const list = currentRating.some((a) => a.toLowerCase() === rating);
        return list;
      });
    }
    return items;
  }, []);
  const SearchResults = React.useMemo(() => {
    return (
      <div className="consent-banner justify-content-start">
        {searchOption.option}
        {searchOption.option === "Actor" &&
          collectionSearchResults.map((g) => (
            <button id={g} key={g} className="button-54" onClick={() => handleActorChange(g)}>
              {g}
            </button>
          ))}
        {searchOption.option === "Director" &&
          collectionSearchResults.map((g) => (
            <button id={g} key={g} className="button-54" onClick={() => handleActorChange(g)}>
              {g}
            </button>
          ))}
        {searchOption.option === "Title" &&
          collectionSearchResults.map((g) => (
            <button id={g} key={g} className="button-54" onClick={() => setSelectedObj(g)}>
              {g.Title}
            </button>
          ))}
      </div>
    );
  }, [searchOption, collectionSearchResults]);
  useEffect(() => {
    const fetchAndUpdateCollection = async () => {
      try {
        const response = await axios.get(`assets/movies.json`);
        const data = response.data;
        if (currentRating[0] === "") {
          setCurrentRating([]);
        }
        if (currentGenres[0] === "") {
          setCurrentGenres([]);
        }
        if (currentDirector[0] === "") {
          setcurrentDirectors([]);
        }
        if (currentActor[0] === "") {
          setcurrentActor([]);
        }
        setCollection(data);
      } catch (error) {
        console.error("Error fetching songs JSON:", error);
      }
    };

    const updateLists = () => {
      let updatedList;
      updatedList = updateCollection(collection, currentRating, currentGenres, currentDirector, currentActor);
      if (updatedList.length !== collection.length) {
        setCollection(updatedList);
        setCurrentPage(1);
      }
    };
    if (currentRating[0] === "" || currentGenres[0] === "" || currentDirector[0] === "" || currentActor[0] === "") {
      fetchAndUpdateCollection();
    } else {
      updateLists();
    }
  }, [currentRating, currentGenres, currentDirector, currentActor, updateCollection, collection]);
  // useEffect(() => {
  //   const handleSorting  = async (order) => {
  //     let currentList = [...collection];
  //     const sortFunctions = {
  //       imdbRating: (a, b) => getValue(b, "imdbRating") - getValue(a, "imdbRating"),
  //       Runtime: (a, b) => getValue(b, "Runtime") - getValue(a, "Runtime"),
  //       Awards: (a, b) => getValue(b, "Awards") - getValue(a, "Awards"),
  //       imdbVotes: (a, b) => getValue(b, "imdbVotes") - getValue(a, "imdbVotes"),
  //       Released: (a, b) => getValue(b, "Released") - getValue(a, "Released"),
  //       Genre: (a, b) => getValue(b, "Genre") - getValue(a, "Genre"),
  //       Box_office: (a, b) => getValue(b, "Box_office") - getValue(a, "Box_office"),
  //       Metascore: (a, b) => getValue(b, "Metascore") - getValue(a, "Metascore"),
  //     };
  //     if (order && sortFunctions[order]) {
  //       const sorted = [...currentList].sort(sortFunctions[order]);
  //       setCollection(sorted);
  //       setSelectedObj([]);
  //     } else {
  //       setCollection([...currentList].sort((a, b) => new Date(b.Released) - new Date(a.Released)));
  //     }
  //     setCurrentPage(1);
  //   };
  //   handleSorting(currentSort);
  // }, [currentSort, collection]);
  useEffect(() => {
    const items = [...collection].filter((a) => a.Released !== "N/A");
    if (currentOption) {
      updateGraph(items, currentOption);
    } else {
      updateGraph(items, "Box_office");
    }
  }, [collection, currentOption, updateGraph]);
  function timeSince(createdAt) {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const seconds = Math.floor((now - createdDate) / 1000);
    const intervals = {
      years: 31536000,
      months: 2592000,
      weeks: 604800,
      days: 86400,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };

    let timeString = "";

    for (const interval in intervals) {
      const diff = Math.floor(seconds / intervals[interval]);
      if (diff >= 1) {
        timeString = `${diff} ${interval} ago`;
        break;
      }
    }

    return timeString || "just now";
  }
  function searchFunction(option, query) {
    var filter;
    filter = query.toLowerCase();
    if (query === "") {
      setsearchOption({});
      setCollectionSearchResults([]);
    } else {
      if (option === "Actor") {
        let it = collectionActor.filter((i) => i.includes(filter));
        setsearchOption({ option: "Actor", query: query });
        setCollectionSearchResults(it);
      }
      if (option === "Director") {
        let it = collectionDirector.filter((i) => i.includes(filter));
        setsearchOption({ option: "Director", query: query });
        setCollectionSearchResults(it);
      }
      if (option === "Title") {
        let it = collection.filter((i) => i.Title.toLowerCase().includes(filter));
        setsearchOption({ option: "Title", query: query });
        setCollectionSearchResults(it);
      }
    }
  }
  const goTo = (Link) => {
    window.location.href = Link;
  };
  return (
    <section className="section">
      <h1 className="text-center">Movies</h1>
      <h5 className="text-center">1980 - 2015</h5>
      <Chart chartType="LineChart" width="100%" height="25vh" style={{ margin: "0", padding: "0" }} data={graphData} options={graphOptions} legendToggle />

      <div className="consent-banner justify-content-start">
        <p>Change chart y axis</p>
        <select aria-label="Order Selection" className="button-54" onChange={(event) => handleGraphChange(event)}>
          <option value="Box_office">Box_office</option>
          <option value="Awards">Awards</option>
          <option value="imdbRating">imdbRating</option>
          <option value="Metascore">Metascore</option>
        </select>
        <button className="button-54" onClick={() => goTo("/CytoScape")}>
          Explore The CytoScape
        </button>
        <p className="text-center">A Polynomial Regression is a mathematical equation that describes a non-linear relationship between an independent variable (x) and a dependent variable (y) using powers of x, this regression uses historical data to identify patterns of this data.</p>
      </div>
      <div className="consent-banner justify-content-start">
        <input autoComplete="off" type="text" id="SearchActor" className="input-53" placeholder="Search Actor, Director, or Movie Title" onChange={(event) => searchFunction("Actor", event.target.value)} />
      </div>
      {searchOption.option && (
        <div className="consent-banner justify-content-start">
          Search By: &nbsp;
          {["Actor", "Director", "Title"]
            .filter((a) => searchOption.option !== a)
            .map((g) => (
              <button id={g} key={g} className="button-54" onClick={() => searchFunction(g, searchOption.query)}>
                {g}
              </button>
            ))}
        </div>
      )}
      {SearchResults}
      <div className="consent-banner justify-content-start">
        <select aria-label="Order Selection" className="button-54" onChange={(event) => handleSort(event)}>
          <option value="Released">Sort by Earliest</option>
          <option value="Box_office">Sort by Box_office</option>
          <option value="Awards">Sort by Awards</option>
          <option value="imdbRating">Sort by imdbRating</option>
          <option value="Metascore">Sort by Metascore</option>
          <option value="imdbVotes">Sort by imdbVotes</option>
        </select>
        <button aria-label="Toggle Ascending/Descending" key="Reverse" id="rocket" className="button-54" onClick={() => handleReverse()}>
          <svg width="24px" height="24px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" strokeWidth="0.00024000000000000003">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.528"></g>
            <g id="SVGRepo_iconCarrier">
              <path d="M9 8.24994C8.81 8.24994 8.62 8.17994 8.47 8.02994L6.5 6.05994L4.53 8.02994C4.24 8.31994 3.76 8.31994 3.47 8.02994C3.18 7.73994 3.18 7.25994 3.47 6.96994L5.97 4.46994C6.26 4.17994 6.74 4.17994 7.03 4.46994L9.53 6.96994C9.82 7.25994 9.82 7.73994 9.53 8.02994C9.38 8.17994 9.19 8.24994 9 8.24994Z" fill="#000000"></path>{" "}
              <path d="M6.5 19.75C6.09 19.75 5.75 19.41 5.75 19V5C5.75 4.59 6.09 4.25 6.5 4.25C6.91 4.25 7.25 4.59 7.25 5V19C7.25 19.41 6.91 19.75 6.5 19.75Z" fill="#000000"></path> <path d="M20 17.25H12C11.59 17.25 11.25 16.91 11.25 16.5C11.25 16.09 11.59 15.75 12 15.75H20C20.41 15.75 20.75 16.09 20.75 16.5C20.75 16.91 20.41 17.25 20 17.25Z" fill="#000000"></path>{" "}
              <path d="M16 11.25H12C11.59 11.25 11.25 10.91 11.25 10.5C11.25 10.09 11.59 9.75 12 9.75H16C16.41 9.75 16.75 10.09 16.75 10.5C16.75 10.91 16.41 11.25 16 11.25Z" fill="#000000"></path> <path d="M14 8.25H12C11.59 8.25 11.25 7.91 11.25 7.5C11.25 7.09 11.59 6.75 12 6.75H14C14.41 6.75 14.75 7.09 14.75 7.5C14.75 7.91 14.41 8.25 14 8.25Z" fill="#000000"></path>{" "}
              <path d="M18 14.25H12C11.59 14.25 11.25 13.91 11.25 13.5C11.25 13.09 11.59 12.75 12 12.75H18C18.41 12.75 18.75 13.09 18.75 13.5C18.75 13.91 18.41 14.25 18 14.25Z" fill="#000000"></path>
            </g>
          </svg>
        </button>
        {collectionRated
          .filter((a) => !currentRating.includes(a.toLowerCase()) && collection.filter((g) => g.Rated.toLowerCase() === a.toLowerCase()).length > 0)
          .map((g) => (
            <button id={g} key={g} className="button-54" onClick={() => HandleRatingChange(g)}>
              {g}({collection.filter((b) => b.Rated.toLowerCase() === g.toLowerCase()).length})
            </button>
          ))}
      </div>
      <div className="consent-banner justify-content-start">
        {collectionGenres
          .filter((a) => !currentGenres.includes(a.toLowerCase()) && collection.filter((g) => g.Genre.split(", ").includes(a)).length > 0)
          .map((g) => (
            <button id={g} key={g} className="button-54" onClick={() => handleGenreChange(g)}>
              {g}({collection.filter((a) => a.Genre.split(", ").includes(g)).length})
            </button>
          ))}
      </div>
      <nav style={{ width: "30%" }}>
        <ul style={{ overflowX: "hidden" }}>
          {(currentGenres.length > 0 || currentRating.length > 0 || currentDirector.length > 0 || currentActor.length > 0) && (
            <li id="r11111" key="r11111">
              <div>
                <h5>Filters</h5>
              </div>
            </li>
          )}
          {(currentGenres.length > 0 || currentRating.length > 0 || currentDirector.length > 0 || currentActor.length > 0) && (
            <li role="button" id="reset" key="reset" className="button-54" onClick={() => handleGenreChange("") + HandleRatingChange("") + handleDirectorChange("") + handleActorChange("")}>
              <div className="section-item">
                <div className="">
                  <h5>Remove All</h5>
                </div>
              </div>
            </li>
          )}
          {currentGenres.length > 0 && (
            <li id="r1111" key="r1111">
              <div>
                <h5>Genres</h5>
              </div>
            </li>
          )}
          {collectionGenres
            .filter((a) => currentGenres.includes(a.toLowerCase()))
            .map((g) => (
              <li role="button" id={"g" + g} key={"g" + g} className="button-54" onClick={() => handleGenreChange(g)}>
                <div className="section-item">
                  <h5>{g}</h5>
                </div>
              </li>
            ))}
          {currentRating.length > 0 && (
            <li id="r111" key="r111">
              <div>
                <h5>Rating</h5>
              </div>
            </li>
          )}
          {currentRating
            .filter((a) => currentRating.includes(a.toLowerCase()))
            .map((g) => (
              <li role="button" id={"r" + g} key={"r" + g} className="button-54" onClick={() => HandleRatingChange("")}>
                <div className="section-item">
                  <h5>{g}</h5>
                </div>
              </li>
            ))}
          {currentDirector.length > 0 && (
            <li id="r11" key="r11">
              <div>
                <h5>Director</h5>
              </div>
            </li>
          )}
          {currentDirector
            .filter((a) => currentDirector.includes(a.toLowerCase()))
            .map((g) => (
              <li role="button" id={"r" + g} key={"r" + g} className="button-54" onClick={() => handleDirectorChange("")}>
                <div className="section-item">
                  <h5>{g}</h5>
                </div>
              </li>
            ))}
          {currentActor.length > 0 && (
            <li id="r1" key="r1">
              <div>
                <h5>Actor</h5>
              </div>
            </li>
          )}
          {currentActor
            .filter((a) => currentActor.includes(a.toLowerCase()))
            .map((g) => (
              <li role="button" id={"r" + g} key={"r" + g} className="button-54" onClick={() => handleActorChange("")}>
                <div className="section-item">
                  <h5>{g}</h5>
                </div>
              </li>
            ))}
          <li id="r" key="r">
            <div>
              <h5>{collection.length}&nbsp;Movies</h5>
            </div>
          </li>
          {collection.slice((currentPage - 1) * PerPage, (currentPage - 1) * PerPage + PerPage).map((movie) => (
            <li role="button" id={movie.id} key={movie.id} className={selectedObj.id === movie.id ? "button-54 active1" : "button-54"} onClick={() => selectItem(movie.id)}>
              <div className="section-item">
                <div className="">
                  <h5>
                    {movie.Title} - {movie.Rated}
                  </h5>
                  <h6>{`${movie.Released.split(" ")[2]} ${movie.Runtime.split(" ")[0]}min ${movie.Director}`}</h6>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </nav>
      <article style={{ width: "70%" }}>
        {selectedObj.Title ? (
          <>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <h2>
                {selectedObj.Title} - {selectedObj.Rated}
              </h2>
              <p>
                {Array(Math.floor(((Number(selectedObj.imdbRating) * 10) / 100) * 5)).fill("★")}
                {Array(5 - Math.floor(((Number(selectedObj.imdbRating) * 10) / 100) * 5)).fill("☆")}
              </p>
            </div>
            <h3>{selectedObj.Released.split(" ")[1] + " " + selectedObj.Released.split(" ")[0] + " " + selectedObj.Released.split(" ")[2]}</h3>
            <p className="breadcrumb">
              Directed by:&nbsp;
              {selectedObj.Director.split(", ").map((a) => (
                <p key={a} className="breadcrumb-item" onClick={() => handleDirectorChange(a)}>
                  {a}
                </p>
              ))}
            </p>
            <p className="breadcrumb">
              Actors:&nbsp;
              {selectedObj.Actors.split(", ").map((a) => (
                <p key={a} className="breadcrumb-item" onClick={() => handleActorChange(a)}>
                  {" "}
                  {a}
                </p>
              ))}
            </p>
            <p>Writers: {selectedObj.Writer}</p>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th className="py-1">Runtime</th>
                  <td className="py-1" align="left">
                    {selectedObj.Runtime}
                  </td>
                </tr>
                <tr>
                  <th className="py-1">Box_office</th>
                  <td className="py-1" align="left">
                    {selectedObj.Box_office}
                  </td>
                </tr>
                <tr>
                  <th className="py-1">Awards</th>
                  <td className="py-1" align="left">
                    {selectedObj.Awards}
                  </td>
                </tr>
                <tr>
                  <th className="py-1">imdbRating</th>
                  <td className="py-1" align="left">
                    {selectedObj.imdbRating}
                  </td>
                </tr>
                <tr>
                  <th className="py-1">imdbVotes</th>
                  <td className="py-1" align="left">
                    {selectedObj.imdbVotes}
                  </td>
                </tr>
                <tr>
                  <th className="py-1">Metascore</th>
                  <td className="py-1" align="left">
                    {selectedObj.Metascore}
                  </td>
                </tr>
                <tr>
                  <th className="py-1">Genre</th>
                  <td className="py-1" align="left">
                    {selectedObj.Genre}
                  </td>
                </tr>
                <tr>
                  <th className="py-1">Country</th>
                  <td className="py-1" align="left">
                    {selectedObj.Country}
                  </td>
                </tr>
                <tr>
                  <th>Link</th>
                  <td>
                    <a target="_blank" rel="noreferrer" href={`http://www.google.co.in/search?q=${"watch " + selectedObj.Title} ${selectedObj.Released.split(" ")[2]}`} title={`http://www.google.co.in/search?q=${"Watch " + selectedObj.Title} ${selectedObj.Released.split(" ")[2]}`}>
                      Find Movie
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <h2>Select A Movie</h2>
            <h3>See its details</h3>
            <p>Box_office: The sum of monies the movie grossed from theater revenue.</p>
            <p>Awards: The amount of rewards and nomination the movie received.</p>
            <p>imdbRating: The IMDb rating on a scale from 1 - 10. 1 meaning the title was terrible and one of the worst titles you've seen and 10 meaning you think it was excellent.</p>
            <p>Metascore: The weighted average of reviews from top critics and publications for a given movie, TV show, video game, or album as declared by Metacritic website.</p>
            <p>imdbVotes: The amount of votes collected by IMDb website.</p>
          </>
        )}
      </article>
      <div className="dock-bottom">
        <div className="consent-banner justify-content-center">
          <button className="button-54" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Prev
          </button>
          {currentPage > Math.ceil(maxPagesToShow / 2) && (
            <button className="button-54" onClick={() => handlePageChange(1)}>
              1
            </button>
          )}
          {currentPage > Math.ceil(maxPagesToShow / 2) && <span>...</span>}
          {getVisiblePages().map((page) => (
            <button key={page} onClick={() => handlePageChange(page)} className={page === currentPage ? "button-54 active1" : "button-54"}>
              {page}
            </button>
          ))}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && <span>...</span>}
          {currentPage < totalPages - Math.floor(maxPagesToShow / 2) && (
            <button className="button-54" onClick={() => handlePageChange(totalPages)}>
              {totalPages}
            </button>
          )}
          <button className="button-54" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      </div>
      {selectedObj.Title && (
        <div className="d-flex flex-column justify-content-center comment-section">
          <h2>Reviews</h2>
          <form className="form-banner justify-content-start">
            <RatingComponent />
            <input placeholder="Persona" className="input-54" />
            <input placeholder="What do you think?" className="input-54" />
            <button type="submit" className="input-54">
              Post
            </button>
          </form>
          {true ? (
            <div className="section-empty">
              <h2>No ones seems to have left a review for this movie yet</h2>
              <h3>Leave a review so your voice will be heard first.</h3>
            </div>
          ) : (
            Array(10)
              .fill({ Title: "Ron Swanson", Comment: "This movie is mid. The weighted average of reviews from top critics and publications for a given movie, TV show, video game, or album as declared by Metacritic website", CreateDate: "01/12/2024", Stars: 3 })
              .map((comment, index) => (
                <div key={"comment-" + index} className="d-flex flex-column comment">
                  <div className="d-flex flex-column justify-content-start">
                    <h2>
                      <svg width="20px" height="20px" version="1.1" viewBox="0 0 20 20" x="0px" y="0px" className="ScSvg-sc-1hrsqw6-1 bYPstv">
                        <g>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 5v8a2 2 0 0 1-2 2H4a2.002 2.002 0 0 1-2-2V5l4 3 4-4 4 4 4-3z"></path>
                        </g>
                      </svg>
                      {comment.Title}
                    </h2>
                    <div className="d-flex flex-row justify-content-start">
                      <p>{timeSince(comment.CreateDate)}</p>
                      <p>
                        {Array(comment.Stars).fill("★")}
                        {Array(5 - comment.Stars).fill("☆")}
                      </p>
                    </div>
                  </div>
                  <p>{comment.Comment}</p>
                </div>
              ))
          )}
          <div className="margin-section">
            <p>Powered by ROY.</p>
            <p>2024</p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Threads;
