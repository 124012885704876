import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../../assets/styles/Layout.css";

const AppNavbar = () => {
  const location = useLocation(); // Get the current location using useLocation hook
  const [activeKey, setActiveKey] = useState("/");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (eventKey) => {
    setIsOpen(false);
    setActiveKey(eventKey);
  };
  React.useEffect(() => {
    setActiveKey(location.pathname);
  }, [location]);
  return (
    <Navbar expand="lg" bg="light" >
      <Navbar.Brand as={Link} to="/">
        <img src="apple-touch-icon.png" alt="Logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsOpen(!isOpen)} />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" in={isOpen} onSelect={handleSelect}>
        <Nav activeKey={activeKey}>
          <Nav.Link className="" as={Link} to="/" eventKey="/" onClick={() => setIsOpen(false)}>
           home
          </Nav.Link>
          <Nav.Link as={Link} to="/Articles" eventKey="/Articles" onClick={() => setIsOpen(false)}>
            articles
          </Nav.Link>
          <Nav.Link as={Link} to="/Services" eventKey="/Services" onClick={() => setIsOpen(false)}>
            music
          </Nav.Link>
          <Nav.Link as={Link} to="/Movies" eventKey="/Movies" onClick={() => setIsOpen(false)}>
          movies
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AppNavbar;
