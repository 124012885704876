import React, { useState, useMemo, useEffect, useCallback } from "react";
import ReactMarkdown from "react-markdown";
import remarkSupersub from "remark-supersub";
import ShareButtons from "./ShareButtons";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import "../assets/styles/Blog.css";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Spinner from "react-bootstrap/Spinner";

const MDViewer = ({ content }) => {
  const [techContent, setContent] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activeBlog, setBlog] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const svgPlaceholder = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIj4KPHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIHN0eWxlPSJmaWxsOmJsYWNrOyIvPjwvc3ZnPg==";
  const directory = content;
  useEffect(() => {
    const dir = directory;
    let markdownFiles = {};
    markdownFiles = require.context(`../assets/markdown/`, false, /\.md$/);
    const filePromises = markdownFiles.keys().map((filePath) => {
      return import(`../assets/markdown/${filePath.replace("./", "")}`)
        .then((module) => ({
          fileName: filePath,
          content: module.default,
        }))
        .catch((error) => {
          console.error("Error importing Markdown file:", filePath, error);
          return null;
        });
    });

    Promise.all(filePromises).then((results) => {
      const fetchPromises = results
        .filter((result) => result !== null)
        .map((result, idx) => {
          return fetch(result.content)
            .then((response) => response.text())
            .then((text) => ({
              Id: idx + 1,
              Image: text.split("\n")[2],
              Title: text.split("\n")[0].replace("# ", "").trim(),
              Filename: result.fileName.replace("./", "").replace(".md", ""),
              Byline: text.split("\n")[6].substring(0, 90).trim() + "...",
              Content: text.split("\n").slice(1).join("\n"),
              ToLink: dir,
            }))
            .catch((error) => {
              console.error("Error fetching Markdown file:", result.content, error);
              return null;
            });
        });

      Promise.all(fetchPromises).then(async (contentMap) => {
        const validContent = contentMap.filter((item) => item !== null);
        validContent.sort((a, b) => (a.Filename > b.Filename ? -1 : 1));
        let postsData = [];
        try {
          const response = await axios.get(`/GetPosts`);
          if (response.status === 200) {
            postsData = response.data;
          } else {
            console.error("Failed to fetch posts");
          }
        } catch (error) {
          console.error("Error fetching posts:", error);
        }
        const contentWithPostData = validContent.map((content) => {
          const post = postsData.find((post) => post.PostFileName === content.Filename + ".md");
          if (post) {
            return {
              ...content,
              PostID: post.PostID || 0,
              Views: post.Views || 0,
              Likes: post.Likes || [],
              Comments: post.Comments || [],
            };
          } else {
            return {
              ...content,
              PostID: 0,
              Views: 0,
              Likes: [],
              Comments: [],
            };
          }
        });
        setContent(contentWithPostData);
        setIsLoading(false);
        const hash = decodeURIComponent(window.location.hash.slice(1)).trim();
        const active = contentWithPostData.find((blog) => blog.Title === hash);
        if (active) {
          handleView(active);
          setBlog(active);
          setSelected(active.Id);
        } else {
          setSelected(null);
        }
      });
    });
  }, [directory]);
  const handleHashChange = (event) => {
    if (window.location.hash === "") {
      setSelected(null);
    }
  };
  const handleView = async (blog) => {
    try {
      const response1 = await axios.post(`/views/${++blog.Views}/${blog.PostID}`);
      if (response1.status === 200) {
      } else {
        console.error("Failed to submit view");
      }
    } catch (error) {
      console.error("Error submitting view:", error);
    }
  };
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "") {
      setSelected(null);
    }
  }, [location]);
  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  useEffect(() => {
    if (selected !== null) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [selected]);

  const handleShowResults = useCallback(
    (blog) => {
      if (selected !== blog.Id) {
        window.location.hash = encodeURIComponent(blog.Title);
        handleView(blog);
        setBlog(blog);
        setSelected((prevSelected) => (prevSelected === blog.Id ? null : blog.Id));
      } else {
        setSelected(null);
        window.location.hash = "";
      }
    },
    [selected]
  );
  const blogCards = useMemo(
    () =>
      techContent.map((blog) => (
        <li role="button" id={blog.id} key={blog.id} className="button-54" onClick={() => handleShowResults(blog)}>
          <img className="" loading="lazy" src={isLoading ? svgPlaceholder : blog.Image.split("](")[1]?.replace(")", "")} alt={`${blog.Title} track cover`}/>
          <div className="d-flex flex-row justify-content-center">
            <span className="eyes">👥{blog.Views}</span>
            <span className="eyes">💖{blog.Likes.length}</span>
            <span className="eyes">📝{blog.Comments.length}</span>
          </div>
          <h1>{blog.Title}</h1>
          <p>{blog.Filename}</p>
      
          <p>{blog.Byline}</p>
        </li>
      )),
    [techContent, isLoading, handleShowResults]
  );
  // const [validated, setValidated] = useState(false);
  // const handleSubmit = async (event) => {
  //   const form = event.currentTarget;
  //   if (form.checkValidity() === false) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //   } else {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     const formData = new FormData(form);
  //     const CommentData = {
  //       UserID: formData.get("UserID"),
  //       Body: formData.get("Body"),
  //       PostID: activeBlog.PostID,
  //     };

  //     try {
  //       const response = await axios.post(
  //         `/comment/${CommentData.UserID}/${CommentData.PostID}/${CommentData.Body}`
  //       );
  //       if (response.status === 200) {
  //         handleShowResults(activeBlog);
  //         form.reset();
  //       } else {
  //         console.error("Failed to submit comment");
  //       }
  //     } catch (error) {
  //       console.error("Error submitting like:", error);
  //     }
  //   }
  //   setValidated(true);
  // };
  const handleLikeSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();

      try {
        const getip = await axios.get(`https://api.ipify.org?format=json`);
        const response = await axios.get(`/like/${ipToLetter(getip.data.ip)}/${activeBlog.PostID}`);

        if (response.status === 200) {
          activeBlog.Likes = response.data.Likes;
          activeBlog.Views = response.data.Views;
          setLiked(!liked);
          setAnimate(true);

          // Reset animation after it finishes
          setTimeout(() => setAnimate(false), 700);
        } else {
          console.error("Failed to submit like");
        }
      } catch (error) {
        console.error("Error submitting like:", error);
      }
    }
  };

  function timeSince(createdAt) {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const seconds = Math.floor((now - createdDate) / 1000);

    const intervals = {
      y: 31536000,
      mo: 2592000,
      w: 604800,
      d: 86400,
      h: 3600,
      min: 60,
      s: 1,
    };

    let timeString = "";

    for (const interval in intervals) {
      const diff = Math.floor(seconds / intervals[interval]);
      if (diff >= 1) {
        timeString = `${diff}${interval[0]} ago`;
        break;
      }
    }

    return timeString || "just now";
  }
  function ipToLetter(ip) {
    // Helper function to convert a number to its binary representation
    function numberToBinaryString(num, bits) {
      return num.toString(2).padStart(bits, "0");
    }

    // Helper function to convert binary to letters (A-Z)
    function binaryToLetters(binary) {
      let num = parseInt(binary, 2);
      // Map numbers (0-25) to A-Z (wrap if greater than 25)
      let letter = String.fromCharCode((num % 26) + 65);
      return letter;
    }

    // IPv4 processing
    if (ip.includes(".")) {
      // Split IPv4 address by dots
      let octets = ip.split(".");
      return octets
        .map((octet) => {
          let binary = numberToBinaryString(parseInt(octet), 8);
          return binaryToLetters(binary);
        })
        .join("");
    }

    // IPv6 processing
    else if (ip.includes(":")) {
      // Split IPv6 address by colons, process each hex group
      let hextets = ip.split(":").map((group) => group || "0"); // Handle empty groups
      return hextets
        .map((hextet) => {
          let binary = numberToBinaryString(parseInt(hextet, 16), 16);
          return binaryToLetters(binary);
        })
        .join("");
    } else {
      throw new Error("Invalid IP address format");
    }
  }
  const [liked, setLiked] = useState(false);
  const [animate, setAnimate] = useState(false);
  if (isLoading) {
    return (
      <div className="container-fluid d-flex flex-wrap justify-content-center align-items-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <div id="mainroot">
      {selected === activeBlog.Id ? (
        <div className="articles-view">
          <ul>{blogCards}</ul>
          <div className="markdown-content">
            <Helmet>
              <meta property="og:type" content="article" />
              <meta property="og:title" content={activeBlog.Title} />
              <meta property="og:url" content="https://royclaudio.pages.dev/" />
              <meta property="og:description" content={activeBlog.Byline} />
              <meta property="article:published_time" content={activeBlog.CreatedAt} />
              <meta property="article:modified_time" content={activeBlog.CreatedAt} />
              <meta property="og:site_name" content="RC Content" />
              <meta property="og:image:alt" content="" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:image" content="assets/RC1.png" />
              <meta property="og:image:width" content="1200px" />
              <meta property="og:image:height" content="628px" />
            </Helmet>
            <h1>{activeBlog.Title}</h1>
            <p>{activeBlog.Filename}</p>
            <div className="d-flex flex-row justify-content-between">
              <ShareButtons url={window.location.href} title={activeBlog.Title} description={activeBlog.Byline} />
              <div className="d-flex flex-row justify-content-evenly">
                <span className="eyes">{activeBlog.Views}</span>
                <span className="eyes">📝{activeBlog.Comments.length}</span>
                <button className={`like-button ${liked ? "liked" : ""} ${animate ? "animate" : ""}`} noValidate onClick={handleLikeSubmit}>
                  {liked ? "❤️" : "🤍"}
                </button>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" drop="down-centered">
                    <span>view {activeBlog.Likes.length} likes </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ padding: "10px", minWidth: "200px" }}>
                    {activeBlog.Likes.map((like) => (
                      <Dropdown.Item disabled className="d-flex flex-row justify-content-between" key={like.LikeID} style={{ fontSize: "0.875rem" }}>
                        <span>💖{like.UserID}</span>
                        <span>{timeSince(like.CreatedAt)}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <ReactMarkdown remarkPlugins={[remarkSupersub]}>{activeBlog.Content}</ReactMarkdown>
          </div>
        </div>
      ) : (
        <div className="articles">
          <h1 className="text-center">Articles</h1>
          <h5 className="text-center">by ROY.</h5>
          <ul>{blogCards}</ul>
        </div>
      )}
    </div>
  );
};
export default MDViewer;
